import React, { useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { css } from "@emotion/react";
import FadeLoader from "react-spinners/FadeLoader";
import { UserContext } from './UserContext';
import axiosInstance from './axiosInstance'; // Ensure axiosInstance is imported

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Enter() {
  let { email, link } = useParams();
  let navigate = useNavigate();

  const { signIn, isStudent, setIsStudent } = useContext(UserContext);


  useEffect(() => {
    let isMounted = true;
  
    async function verifyAndNavigate() {
      if (isMounted) {
        const user = await signIn(email, link, isStudent);
        console.log('User after signIn:', user);
  
        if (user) {
          try {
            console.log(`Sending updateLastLogin request for ${email}`);
            const updateRes = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/users/updateLastLogin`, { email });
            console.log('Response from updateLastLogin:', updateRes.data);
          } catch (error) {
            console.error('Error during updateLastLogin:', error);
          }


           // Store user email temporarily in sessionStorage
           sessionStorage.setItem('userEmail', email);
           sessionStorage.setItem('userRole', user.role);

  
          // Check if user is a student and update the context
          if (user.role === 'student') {
            setIsStudent(true);
            navigate('/sat-dashboard');
          } else if (user.role === 'admin') {
            navigate('/admin');
          } else {
            navigate('/dashboard'); // For other users
          }
        } else {
          navigate('/login-signup');
        }
      }
    }

    verifyAndNavigate();
    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [email, link, navigate, signIn, setIsStudent]);
 useEffect(() => {
  const tabId = 'app_active_tab';

  if (localStorage.getItem(tabId)) {
    // If an active tab is detected, redirect to a message or dashboard
    window.location.href = '/dashboard'; // Redirect any new tabs to the dashboard
    return;
  }

  // Set the flag for the active tab once the user logs in via magic link or manually
  localStorage.setItem(tabId, 'true');

  // Cleanup when the tab is closed or refreshed
  const handleTabClose = () => {
    localStorage.removeItem(tabId);
  };

  window.addEventListener('beforeunload', handleTabClose);

  return () => {
    window.removeEventListener('beforeunload', handleTabClose);
    localStorage.removeItem(tabId); // Cleanup on component unmount
  };
}, []);




  return (
    <div>
      <p>Verifying your magic link</p>
      <FadeLoader color={'black'} loading={true} css={override} size={50} />
    </div>
  );
}
