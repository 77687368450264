const navList = [
    {
        _id: 1,
        name: 'Contacts',
        icon: 'bi bi-person',
    },
    {
        _id: 2,
        name: 'Live Chat',
        icon: 'bi bi-mic-fill',
    },
    {
        _id: 3,
        name: 'Deliverable',
        icon: 'bi bi-card-list',
    },
    {
        _id: 4,
        name: 'Block User',
        icon: 'bi bi-box-arrow-in-right',
    },
    {
        _id: 5,
        name: 'Settings',
        icon: 'bi bi-gear',
    },
    {
        _id:6,
        name: 'F.A.Q',
        icon: 'bi bi-question-circle',
    },
    {
        _id: 7,
        name: 'SAT',
        icon: 'bi bi-file-earmark',
    },

];

 

export default navList;