import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { PriceContext } from '../Context/PriceContext';
import axiosInstance from '../../../axiosInstance';
import './ConfirmationPayment.css';
import moment from 'moment-timezone';

const API_URL = process.env.REACT_APP_API_URL;
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;


const URL = `${API_URL}/api/track`;

const loadPayPalScript = (finalTotalCost) => {
  const script = document.createElement('script');
  script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=USD`;
  script.async = true;
  script.onload = () => {
    window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: finalTotalCost, // Dynamically using finalTotalCost
            },
          }],
        });
      },
      onApprove: (data, actions) => {
        return actions.order.capture().then(function (details) {
          alert('Transaction completed by ' + details.payer.name.given_name);
          // Handle transaction success here
        });
      },
      onError: (err) => {
        console.error('PayPal Checkout error:', err);
        // Handle errors here
      }
    }).render('#paypal-buttons-container');
  };
  document.body.appendChild(script);
};
    
const calculateDeliveryPrice = (initialCost, deliveryTurnaround) => {
  const cost = parseFloat(initialCost);
  switch (deliveryTurnaround) {
    case 'Same Day (4-6hrs)':
      if (cost < 100) return 169.46;
      if (cost >= 100 && cost < 320) return 232.88;
      if (cost >= 320 && cost < 499) return 338.59;
      if (cost >= 500 && cost < 899) return 423.16;
      if (cost >= 900 && cost < 1499) return 835.45;
      return 0;
    case 'Next Day (24hrs)':
      return cost * 0.33;
    case 'Priority (72 hours)':
      return cost * 0.06;
    case 'Standard (5-7 days)':
    default:
      return 0;
  }
};

const ConfirmationPayment = () => {
  const { priceData } = useContext(PriceContext);
  const [coupon, setCoupon] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [finalTotalCost, setFinalTotalCost] = useState('0.00');
  const [initialCost, setInitialCost] = useState(0);
  const [orderSubmitted, setOrderSubmitted] = useState(false); // State for tracking order submission


  const couponCodes = {
    'DISCOUNT25': 0.25,
    'SAVE10': 0.10,
    'SUMMER15': 0.15,
    'AKU_DISCOUNT_25': 0.25,
    'HORTON_FREE_PROJECT': 200,
  };

  const isCouponExpired = (expirationDate) => {
    const currentDate = moment().tz('America/New_York');
    const expiration = moment.tz(expirationDate, 'America/New_York');
    return currentDate.isAfter(expiration);
  };

  const calculateInitialCost = () => {
    let cost = 0;
    const wordcount = parseInt(priceData.wordcount, 10);
    if (wordcount <= 500) {
      cost += 58.49;
    } else if (wordcount > 500 && wordcount <= 2000) {
      cost += 190.63;
    } else if (wordcount > 2000 && wordcount <= 2500) {
      cost += 291.05;
    } else if (wordcount > 2500 && wordcount <= 4000) {
      cost += 349.19;
    } else if (wordcount > 4000 && wordcount <= 4500) {
      cost += 365.05;
    } else if (wordcount > 4500 && wordcount <= 6000) {
      cost += 423.19;
    } else if (wordcount > 6000 && wordcount <= 6500) {
      cost += 481.33;
    } else if (wordcount > 6500 && wordcount <= 8000) {
      cost += 708.61;
    } else if (wordcount > 8000 && wordcount <= 8500) {
      cost += 766.74;
    } else if (wordcount > 8500 && wordcount <= 10000) {
      cost += 835.45;
    } else {
      cost += 835.45;
      const additionalWords = wordcount - 10000;
      const additionalSets = Math.ceil(additionalWords / 500);
      const additionalCost = additionalSets * 68.71;
      cost += additionalCost;
    }

    if (priceData.services) {
      for (const [service, isSelected] of Object.entries(priceData.services)) {
        if (isSelected) {
          switch (service) {
            case 'DRR':
              cost += 528.90;
              break;
            case 'Investigate':
              cost += 169.49;
              break;
            case 'Research':
              cost += 528.90;
              break;
            case 'Design':
              cost += 232.88;
              break;
            case 'Review':
              cost += 10.93;
              break;
            case 'Video':
              cost += 42.64;
              break;
            case 'Layout':
              cost += 338.62;
              break;
            case 'Audio':
              cost += 10.93;
              break;
            case 'Convert':
              cost += 1.38;
              break;
            case 'Compress':
              cost += 26.78;
              break;
            case 'Consultation':
              cost += 42.64;
              break;
            case 'Merge':
              cost += 1.38;
              break;
            default:
              break;
          }
        }
      }
    }
    return cost.toFixed(2);
  };
  useEffect(() => {
    const initial = calculateInitialCost();
    setInitialCost(initial);
  
    const deliveryCharge = calculateDeliveryPrice(initial, priceData.deliveryTurnaround);
    const totalCost = (parseFloat(initial) + parseFloat(deliveryCharge)).toFixed(2);
    setFinalTotalCost(totalCost);
  
    // Load PayPal script with the initial total cost
    loadPayPalScript(totalCost);
  }, [priceData]);
  
  useEffect(() => {
    // Automatically apply coupon when a valid one is entered
    if (couponCodes[coupon.toUpperCase()]) {
      applyCoupon();
    }
  }, [coupon]);
  
  // Re-load PayPal script after coupon is applied
  const applyCoupon = () => {
    const discountValue = couponCodes[coupon.toUpperCase()];
    let parsedTotalCost = parseFloat(finalTotalCost);
    if (isNaN(parsedTotalCost)) {
      alert('Error calculating total cost. Please check the values.');
      return;
    }
  
    if (coupons.includes(coupon.toUpperCase())) {
      alert('Coupon already applied.');
      return;
    }
  
    if (coupon.toUpperCase() === 'HORTON_FREE_PROJECT') {
      const expirationDate = '2024-12-22';
      if (isCouponExpired(expirationDate)) {
        alert('Coupon has expired.');
        setDiscount(0);
        return;
      }
      let newTotalCost = Math.max(parsedTotalCost - 200, 0).toFixed(2);
      setDiscount(200);
      setFinalTotalCost(newTotalCost);
      setCoupons([...coupons, coupon.toUpperCase()]);
    } else if (discountValue) {
      let discountedTotalCost = (parsedTotalCost - parsedTotalCost * discountValue).toFixed(2);
      setDiscount(discountValue);
      setFinalTotalCost(discountedTotalCost);
      setCoupons([...coupons, coupon.toUpperCase()]);
    } else {
      alert('Invalid coupon. Please try again.');
      setDiscount(0);
    }
  
    // Re-load PayPal script with updated total cost
    loadPayPalScript(finalTotalCost);
  };
  

  
  const handlePaymentConfirmation = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const data = {
      email: priceData.email || 'no@Email.com',
      total: finalTotalCost,
      wordCount: priceData.wordcount,
      deliveryTurnaround: priceData.deliveryTurnaround,
      deadline: priceData.deadline,
      documentType: priceData.documentType,
      additionalServices: Object.keys(priceData.services).filter(service => priceData.services[service]),
      couponCode: coupons,
      instructions: priceData.instructions,
      sessionId: sessionStorage.getItem('sessionId') || 'unknown'
    };

    try {
      const response = await axiosInstance.post(URL, data);
      if (response.status === 200) {
        console.log('Data sent successfully');
        setOrderSubmitted(true); // Set order as submitted when successful
        loadPayPalScript(finalTotalCost); // Load PayPal script only after successful post
      } else {
        console.log('Error sending data');
      }
    } catch (error) {
      console.error('Error during data submission:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="confirmation">
      <Helmet>
        <title>Confirmation & Payment - Contranorm Media & Production</title>
      </Helmet>

        <div className="paypal_container">
          {/* Render PayPal buttons dynamically */}
          <div id="paypal-buttons-container"></div>
          {isSubmitting && <p>Submitting...</p>}
          <div className="total_cost">
            <h2>Initial Cost: ${finalTotalCost}</h2>
          </div>

          <div className="coupon_section">
            <input
              type="text"
              placeholder="ENTER COUPON CODE"
              value={coupon}
              onChange={(e) => setCoupon(e.target.value)}
            />
          </div>
          {/* Conditionally render the button based on orderSubmitted state */}
        {!orderSubmitted && (
          <button className="apply" onClick={() => handlePaymentConfirmation(finalTotalCost)}>
            Submit Order
          </button>
        )}
          
        

      </div>
      
    </section>
  );
};

export default ConfirmationPayment;