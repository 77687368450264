import React, { useState, useContext } from 'react';
import email_icon from '../Assets/email_icon.png';
import user_icon from '../Assets/user_icon.png';
import './LoginSignup.css';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../../axiosInstance'; // Updated import
import { UserContext } from '../../../UserContext'; 
import Validation from './LoginSignupValidation';
import { Link, useNavigate } from 'react-router-dom';
import UserEmail from '../UserEmail'; // Import the UserEmail component
import Mount from '../Mount';
import AlertBanner from '../Banner/AlertBanner';

const API_URL = process.env.REACT_APP_API_URL;
console.log('API_URL:', API_URL);
console.log('Full API_URL:', `${API_URL}/users/enter`);

const LoginSignup = () => { 
  const { signIn } = useContext(UserContext); 
  const [action, setAction] = useState("Sign Up");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState('');
  const [isStudent, setIsStudent] = useState(false);
  const [errors, setErrors] = useState({});
  const [isPasted, setIsPasted] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state

  const [successMessage, setSuccessMessage] = useState(''); // Add state for success message
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const navigate = useNavigate();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    // Convert email to lowercase and update state
    setEmail(e.target.value.toLowerCase());
  };


  const handleEmailPaste = (e) => {
    e.preventDefault(); // Prevent pasting
    setIsPasted(true);
    alert('Please type your primary email address.');
  };

  const handleLoginClick = async () => {
    if (loading) return; // Prevent multiple submissions
    setLoading(true);
    setShowAlert(false); // Hide banner on new attempt
    setSuccessMessage(''); // Clear any previous success message

    if (action === "Login" && email) {
        const values = { 
            email,
            isPasted 
        };

        const validationErrors = Validation(values);
        setErrors(validationErrors);

        const hasValidationErrors = Object.values(validationErrors).some(error => error !== "");
        if (!hasValidationErrors) {
            try {
                const res = await axiosInstance.post(`${API_URL}/users/enter`, values, { withCredentials: true });
                if (res.data.ok) {
                    // Show alert and clear inputs
                    alert('Magic link sent to your email.');
                    setEmail(''); // Clear email input
                } else {
                    alert(res.data.message);
                }
            } catch (err) {
                console.error('Error logging in:', err);
                setShowAlert(true); // Show banner on any error
                if (err.response && err.response.data && err.response.data.error) {
                    alert(err.response.data.error);
                } else {
                    alert('An unexpected error occurred');
                }
            }
        }
    }

    setLoading(false);
    setAction("Login");
  };

  const handleSignupClick = async () => {
    if (loading) return; // Prevent multiple submissions
    setLoading(true);
    setShowAlert(false); // Hide banner on new attempt
    setSuccessMessage(''); // Clear any previous success message

    if (action === "Sign Up" && username && email) {
      const values = { 
        username, 
        email, 
        isPasted,
        role: isStudent ? 'student' : 'user'  // Add role based on checkbox 
      };
      const validationErrors = Validation(values);
      setErrors(validationErrors);
      if (errors.email === "" && errors.username === "") {
        try {
          const res = await axiosInstance.post(`${API_URL}/users/signup`, values); // Use axiosInstance
          if (res.data.ok) {
            // Show alert and clear inputs
            alert('Signup successful!');
            setUsername(''); // Clear username input
            setEmail(''); // Clear email input
            navigate('/price-calculator');
          } else {
            alert(res.data.message);
          }
        } catch (err) {
          console.error('Error signing up:', err);
          setShowAlert(true); // Show banner on any error
          if (err.response && err.response.data && err.response.data.error) {
            alert(err.response.data.error);
          } else {
            alert('An unexpected error occurred');
          }
        }
      }
    }
    setLoading(false);
    setAction("Sign Up");
  };

  const handleFormKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Login" ? "Login" : "Sign Up"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
      <div className="header">
          <div className="text">{action}</div>
          <div className="underline"></div>
        </div>
        
        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
        <form action="" onKeyPress={handleFormKeyPress}>
          <div className="inputs">
            {action === "Login" ? (
              <div></div>
            ) : (
              <div className="input">
                <img src={user_icon} id="user_icon" alt="" />
                <input 
                  type="text" 
                  placeholder="Full Legal Name" 
                  value={username} 
                  onChange={handleUsernameChange} 
                />
                {errors.username && <span className='text-danger'>{errors.username}</span>}
              </div>
            )}
            <div className="input">
              <img src={email_icon} id="email_icon" alt="" />
              <UserEmail
                userEmail={email}
                enterEmail={handleEmailChange}
                handleEmailPaste={handleEmailPaste}
              />
              {errors.email && <span className='text-danger'>{errors.email}</span>}
            </div>
          
            {action === "Sign Up" && (
              <div className="checkbox-container">
                <button 
                  type="button"  // Prevents it from acting as a submit button
                  className={isStudent ? 'checked' : ''} 
                  onClick={(e) => {
                    e.preventDefault(); // Prevent form submission
                    setIsStudent(!isStudent); // Toggle the state
                  }} 
                  aria-label="Student Registration Toggle"
                ></button>
                <p>I'm a student</p>
              </div>
            )}

          </div>
         
        </form>
      </div>
      <div className="submit-container">
      {action === "Sign Up" ? (
            <div></div>
          ) : (
            <div className="link_one"><Link to="/policies"><span>Terms & Conditions</span></Link></div>
          )}
          {action === "Login" ? (
            <div></div>
          ) : (
            <div className="link_two"><Link to="/privacy"><span>Privacy Policy</span></Link></div>
          )}
        <div className={action === "Login" ? "submit gray" : "submit"} onClick={handleSignupClick}>Sign Up</div>
        <div className={action === "Sign Up" ? "submit gray" : "submit"} onClick={handleLoginClick}>Login</div>
      </div>
      <AlertBanner 
      showBanner={showAlert} 
      showSaveButton={false} 
      handleCancel={() => setShowAlert(false)}>
          <div style={{ display: 'flex',  width: '100%' }}>
            <span style={{ flex: 1 }}>It appears that you may be in a geo-restricted area or this feature has been temporarily disabled by your device.</span>
         
           
          </div>
        </AlertBanner>
    </Mount>
  );
}

export default LoginSignup;
