// ClientApp.jsx
import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { UserContext } from '../UserContext';
import './ClientApp.css';
import Home from './Pages/Home.jsx';
import LoginSignup from './Components/LoginSignup/LoginSignup.jsx';
import ContactUs from './Pages/ContactUs.jsx';
import PriceCalculator from './Components/PriceCalculator/PriceCalculator.jsx';
import { PriceProvider } from './Components/Context/PriceContext.js';
import ConfirmationPayment from './Components/ConfirmationPayment/ConfirmationPayment.jsx';
import AddServices from './Components/AddServices/AddServices.jsx';
import Upload from './Components/Upload/Upload.jsx';
import FlipBook from './Components/FlipBook.jsx';
import TestComponent from './Components/TestComponent.jsx';
import Privacy from './Components/Privacy/Privacy.jsx';
import Policies from './Pages/Policies.jsx';
import Blog from './Pages/Blog.jsx';
import Dashboard from './Pages/Dashboard.jsx';
import SATDashboard from './Pages/SATDashboard.jsx';
import AboutUs from './Components/AboutUs/AboutUs.jsx';
import Hook from './Components/Hook/Hook.jsx';
import HookB from './Components/Hook/HookB.jsx';
import HookC from './Components/Hook/HookC.jsx';
import SAT from './Components/SAT/SAT.jsx';
import SAThook from './Components/SAT/SAThook.jsx';
import CGI from './Components/CGI/CGI.jsx';
import Error404 from './Components/Error404/Error404.jsx';

import Quiz from './Components/SAT/Quiz.jsx'; // Adjust the path accordingly


const ProtectedRoute = ({ element, rolesAllowed }) => {
  const { user } = useContext(UserContext);

  // If user is not authenticated, redirect to login
  if (!user) {
    return <Navigate to="/login-signup" replace />;
  }

  // If user role is not allowed, redirect to home
  if (rolesAllowed && !rolesAllowed.includes(user?.role)) {
    return <Navigate to="/" replace />;
  }

  // If all checks pass, render the element
  return element;
};

const ClientApp = () => {
  const { user, signIn, signup, userEmail } = useContext(UserContext);

  return (
    <PriceProvider>
      <Routes>
        <Route path="/" element={<AboutUs />} />
        <Route path="/home" element={<Home />} />

        {/* Protected routes for dashboards */}
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} rolesAllowed={['user']} />}
        />
        <Route
          path="/sat-dashboard"
          element={<ProtectedRoute element={<SATDashboard />} rolesAllowed={['student']} />}
        />

        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/login-signup"
          element={<LoginSignup signIn={signIn} signup={signup} userEmail={userEmail} />}
        />
        <Route path="/price-calculator" element={<PriceCalculator />} />
        <Route path="/confirmation-payment" element={<ConfirmationPayment />} />
        <Route path="/add-services" element={<AddServices />} />
        <Route path="/upload" element={<Upload />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="/error" element={<Error404 />} />

        <Route path="/hook" element={<Hook />} />
        <Route path="/hookb" element={<HookB />} />
        <Route path="/hookc" element={<HookC />} />
        <Route path="/sat" element={<SAT />} />
        <Route path="/sat-hook" element={<SAThook />} />
        <Route path="/cgi" element={<CGI />} />
        <Route path="/articles" element={<Blog />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/flipbook" element={<FlipBook />} />
        <Route path="/test" element={<TestComponent />} />
        <Route path="/sat-practice" element={<Quiz />} />
      </Routes>
    </PriceProvider>
  );
};

export default ClientApp;
