import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit'; 
import { saveAs } from 'file-saver';
//import { PDFDocument, rgb } from 'pdf-lib';


export async function generateCertificate({ name, startDate, completionDate, score, hours, updateStatusMessage, qrCodeImage  }) {

  

    const formatDate = (dateStr) => {
        const [year, month, day] = dateStr.split('-');
        const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        return `${day}${monthNames[parseInt(month, 10) - 1]}${year}`;
    };

    const formattedStartDate = startDate ? formatDate(startDate) : '';
    const formattedCompletionDate = completionDate ? formatDate(completionDate) : '';


    try {
        updateStatusMessage('This may take a minute...');
        const templateBytes = await fetch('/SAT_CompletionCertificate-08.pdf').then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(templateBytes);
        pdfDoc.registerFontkit(fontkit);

        const fontBytes = await fetch('/PinyonScript.ttf').then(res => res.arrayBuffer());
        const scriptFont = await pdfDoc.embedFont(fontBytes);

        const page = pdfDoc.getPage(0);

        const pxToPoints = (px) => (px / 300) * 72;

        
        const textWidth = scriptFont.widthOfTextAtSize(name, 36);
        const xStartName = pxToPoints(900);
        const xEndName = pxToPoints(1980); 
        const availableWidthName = xEndName - xStartName;
        const centeredXName = xStartName + (availableWidthName - textWidth) / 2;

        updateStatusMessage('Verifying your credentials...');
        page.drawText(name, {
            x: centeredXName,
            y: pxToPoints(1200),
            size: 80,
            font: scriptFont,
            color: rgb(0, 0, 0),
        });

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

       
        const startDateWidth = helveticaFont.widthOfTextAtSize(formattedStartDate, 24);
        const xStartStartDate = pxToPoints(370)
        const xEndStartDate = pxToPoints(470)
        const availableWidthStartDate = xStartStartDate + startDateWidth;
        const centeredXStartDate = xStartStartDate + (availableWidthStartDate - startDateWidth) / 2;

        page.drawText(formattedStartDate, {
            x: centeredXStartDate,
            y: pxToPoints(1050),
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });

      
        const completionDateWidth = helveticaFont.widthOfTextAtSize(formattedCompletionDate, 24);
        const xStartCompletionDate = pxToPoints(1340);
        const xEndCompletionDate = pxToPoints(1434);
        const availableWidthCompletionDate = xEndCompletionDate - xStartCompletionDate;
        const centeredXCompletionDate = xStartCompletionDate + (availableWidthCompletionDate - completionDateWidth) / 2;

        page.drawText(formattedCompletionDate, { 
            x: centeredXCompletionDate,
            y: pxToPoints(1050),
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });


        const hoursText = `${hours.toFixed(2)}`;
        const hoursWidth = helveticaFont.widthOfTextAtSize(hoursText, 12);
        const xStartHours = pxToPoints(1920);
        const xEndHours = pxToPoints(1990); 
        const availableWidthHours = xEndHours - xStartHours;
        const centeredXHours = xStartHours + (availableWidthHours - hoursWidth) / 2;
    
        page.drawText(hoursText, {
            x: centeredXHours,
            y: pxToPoints(1110),
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });

        const scoreWidth = helveticaFont.widthOfTextAtSize(`Score: ${score}`, 24);
        const xStartScore = pxToPoints(2740);
        const xEndScore = pxToPoints(2750);
        const availableWidthScore = xEndScore - xStartScore;
        const centeredXScore = xStartScore + (availableWidthScore - scoreWidth) / 2;

        updateStatusMessage('Selecting your highest score.');
        page.drawText(`${score}`, {
            x: centeredXScore,
            y: pxToPoints(873),
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });

        const qrImageBytes = await fetch(qrCodeImage).then(res => res.arrayBuffer());
    const qrImage = await pdfDoc.embedPng(qrImageBytes); // Use embedPng for base64 image data

    // Define the position and size of the QR code on the certificate
    const qrSize = 100;
    page.drawImage(qrImage, {
        x: 700, // Adjust position as needed
        y: 30, // Adjust position as needed
        width: qrSize,
        height: qrSize
    });

        updateStatusMessage('Finalizing your transcript');
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        saveAs(blob, 'Certificate-of-Completion.pdf');
        
    } catch (error) {
        console.error("Error generating certificate:", error);
    }
}
