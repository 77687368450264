import React, { useEffect, useState } from 'react';
import videoSrc from '../Assets/404Ether.mp4';
import './Error404.css';

const Error404 = () => {
  const [visible, setVisible] = useState(false); // Control visibility
  const [fadeOut, setFadeOut] = useState(false); // Control fade-out

  useEffect(() => {
    setVisible(true); // Show the message initially

    // Start fade-out effect after 2 seconds (allowing for the grow to complete)
    const timer = setTimeout(() => {
      setFadeOut(true);
    }, 2000); // Adjust to match the new grow duration

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  // Handle when the fade-out animation ends
  const handleAnimationEnd = () => {
    if (fadeOut) {
      setVisible(false); // Remove from DOM after fade-out
    }
  };

  return (
    <div className="errorContainer">
      <video autoPlay loop muted className="backgroundVideo">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {visible && (
        <p className={fadeOut ? 'fadeOut' : ''} onAnimationEnd={handleAnimationEnd}>
           This page is transitioning from imagination to reality. 
        </p>
      )}
    </div>
  );
};

export default Error404;
