import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; // Ensure this path is correct
import './Hook.css'; // Import CSS for PrivacyComponent if needed

const Hook = () => {
  const [action, setAction] = useState("Document Preparation"); // Initial state is "Document Preparation"
  const navigate = useNavigate(); // Use navigate for navigation

  const handleHomeClick = () => {
    navigate('/home'); // Navigate to home page
  };

  const handleDocumentPreparationClick = () => {
    setAction("Document Preparation"); // Set action state to "Document Preparation" when clicked
  };

  const handleConfidenceClick = () => {
    setAction("Confidence"); // Set action state to "Confidence" when clicked
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Document Preparation" ? "Document Preparation" : "Confidence"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
        <div className="header">
          <div className="text">{action === "Document Preparation" ? "Document Preparation" : "File Your Legal Paperwork with Confidence"}</div>
          <div className="underline"></div>
        </div>

        <>
          {action === "Document Preparation" ? (
            <>
            <br />
            <br />
            <br />
            
              <h4>Need a Stress-Free Way to Prepare for Your Case?</h4>
              <br />
              <p>Attend any administrative hearing with confidence, knowing that every document that you've submitted has been meticulously prepared.  With Contranorm Media & Production's document preparation service, we don't just help you submit your paperwork— we craft  comprehensive arguments to validate your evidence and fortify your case.</p>
            </>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <br />
              <br />
              <br />
              <p>Preparing legal documents can be challenging, but it doesn't have to be. Let our team help you get your pleading filed quickly and accurately, so you can focus on winning your case.
                <br /><br />Our expert writers specialize in administrative processes from formal grievances to EEOC complaints, MSPB appeals, PFR, and Petitions for Enforcement. So, if you are a federal employee or government worker and believe that you've been treated unfairly, exercise your rights, and take action today!
              </p>
              <br />
              <p className="wrap-text">
                Learn to&nbsp;
                <Link to="/articles" className="link-with-space">manage</Link>
                &nbsp;your own administrative filings.
              </p>
            </div>
          )}
        </>
      </div>

            <div className="submit-container">
        {action === "Document Preparation" ? (
          <>
            <div className="submit gray" onClick={handleHomeClick}>Go Back</div> {/* Gray here */}
            <div className="submit" onClick={handleConfidenceClick}>Learn More</div> {/* Normal here */}
          </>
        ) : (
          <>
            <div className="submit gray" onClick={handleDocumentPreparationClick}>Go Back</div> {/* Gray here */}
            <Link to="/price-calculator" className="submit">Get Started</Link> {/* Normal here */}
          </>
        )}
      </div>

    </Mount>
  );
};

export default Hook;
