// src/axiosInstance.js
import axios from 'axios';

axios.defaults.withCredentials = true;

const instance = axios.create({
  baseURL:process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Retry the request in case of network errors or connection reset
const retryRequest = async (error) => {
  if (!error.config || error.config.__retryCount >= 1) {
    return Promise.reject(error); // Reject if already retried
  }

  error.config.__retryCount = (error.config.__retryCount || 0) + 1;

  return new Promise((resolve) => {
    setTimeout(() => resolve(instance(error.config)), 1000); // Retry after a delay
  });
};

// src/axiosInstance.js
instance.interceptors.response.use(
  (response) => {
    const contentType = response.headers['content-type'];

    if (contentType && contentType.includes('text/html')) {
      console.log('Interceptor detected HTML response');
      // Explicitly throw an error to catch it in the component
      return Promise.reject({ message: 'Session expired', sessionExpired: true });
    }

    return response; // Return the response if it's not HTML
  },
  async (error) => {
    if (error.code === 'ECONNRESET' || error.message.includes('Network Error')) {
      console.warn('Connection reset or network error; retrying...');
      return retryRequest(error);
    }

    if (error.response && error.response.status === 410) {
      console.error('Interceptor detected 410 Gone response');
      // Handle the 410 response, e.g., notify the user the file is gone
      return Promise.reject({ message: 'File no longer available', fileGone: true });
    }

    // Handle other errors
    console.error('Interceptor error:', error);
    return Promise.reject(error);
  }
);



export default instance;
