import React, { createContext, useState } from 'react';

export const PriceContext = createContext();

export const PriceProvider = ({ children }) => {
  const [priceData, setPriceData] = useState({
    email: '',
    wordcount: '',
    deliveryTurnaround: '',
    deadline: '', // Add deliveryDate field
    documentType: '',
    couponCode: [],
    services: {
      DRR: false,
      Video: false,
      Review: false,
      Consultation: false,
      Investigate: false,
      Audio: false,
      Research: false,
      Merge: false,
      Design: false,
      Layout: false,
      Convert: false,
      Compress: false,
    },
    instructions: '', // Add instructions field
    uploadedFiles: []
  });
 
  



  return (
    <PriceContext.Provider value={{ priceData, setPriceData}}>
      {children}
    </PriceContext.Provider>
  );
};
