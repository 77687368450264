import React, { useEffect, useState, useContext, useCallback } from 'react';
import Module from './Module';
import axiosInstance from '../../../axiosInstance';
import { UserContext } from '../../../UserContext'; 
import { calculateScaledScore } from './Score';
import './Quiz.css';
import Lottie from 'lottie-react';
import ProgressBar from './ProgressBar';
import animationData from '../Assets/animationData.json'; 
import monkeyAnimation from '../Assets/monkeyAnimation.json'; 
import { generateCertificate } from './generateCertificate';
import { generateQRCode } from './generateQRCode';
import { RiQrCodeLine } from 'react-icons/ri';




import { AtomSpinner } from 'react-epic-spinners'; 

const API_URL = process.env.REACT_APP_API_URL;
const DEFAULT_QUIZ_ID = 'QUIZ_1';

const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const Quiz = () => {
    const { user } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(''); 
    const [modules, setModules] = useState([]);
    const [activeModuleIndex, setActiveModuleIndex] = useState(0);
    const moduleTitle = `Module ${activeModuleIndex + 1}`.toUpperCase();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionId, setQuestionId] = useState(null);
    const [question, setQuestion] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [progressCount, setProgressCount] = useState(0);
    const [answeredQuestions, setAnsweredQuestions] = useState(new Set());
    const [totalAnsweredQuestions, setTotalAnsweredQuestions] = useState(0); 
    const [correctAnswersInModule, setCorrectAnswersInModule] = useState(0);
    const [skippedQuestions, setSkippedQuestions] = useState(new Set());
    const [hasAnswered, setHasAnswered] = useState(new Set());
    const [showExplanation, setShowExplanation] = useState(false);
    const [explanationText, setExplanationText] = useState('');

    const [isCorrect, setIsCorrect] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState(0);
    const [answerDetails, setAnswerDetails] = useState({    
        timeLeft: null,
        totalPauseDuration: null
    });

    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
    const [quizFinished, setQuizFinished] = useState(false);
    const [lastQuizCompleted, setLastQuizCompleted] = useState(false);
    const [scaledScoreState, setScaledScore] = useState(null); 
    const [quizID, setQuizID] = useState(DEFAULT_QUIZ_ID);
    const [noMoreQuizzes, setNoMoreQuizzes] = useState(false); 
    const [pauseCount, setPauseCount] = useState(0);
    const [totalPauseDuration, setTotalPauseDuration] = useState(0); 
    const [extraTimeAfforded, setExtraTimeAfforded] = useState(0);
    const [timeLeft, setTimeLeft] = useState(1920);
    const totalHours = ((1920 - timeLeft) + totalPauseDuration) / 3600;
    const [independentStudyHours, setIndependentStudyHours] = useState(0); 
    const [certificateData, setCertificateData] = useState({
        name: '',
        startDate: '',
        completionDate: '',
        score: 0,
        hours: 0
    });
    const [isAnimationPlaying, setIsAnimationPlaying] = useState(false);
    const [showPayPal, setShowPayPal] = useState(false);

    const [paypalSuccess, setPaypalSuccess] = useState(true);
    const [transcriptUrl, setTranscriptUrl] = useState(null);
    const [statusMessage, setStatusMessage] = useState('Generating your Certificate...');
    const updateStatusMessage = (message) => { 
        setStatusMessage(message);
    };
    const savedProgress = localStorage.getItem(user?.email ? `${user.email}_quizProgress` : 'quizProgress');


    const [isRestoration, setIsRestoration] = useState(false);

    const onSetSelectedOption = (officialAnswer) => {
        setSelectedOption(officialAnswer);
        saveProgress(officialAnswer); 
    };
    
    const onSetSkippedQuestions = (updatedSkippedQuestions) => {
        setSkippedQuestions((prevSkipped) => {
            const updatedSkipped = new Set(prevSkipped);
    
            answeredQuestions.forEach((answeredQuestionIndex) => {
                if (updatedSkipped.has(answeredQuestionIndex)) {
                    updatedSkipped.delete(answeredQuestionIndex); // 
                }
            });
    
        
            updatedSkippedQuestions.forEach((questionIndex) => {
                if (!answeredQuestions.has(questionIndex)) {
                    updatedSkipped.add(questionIndex);
                }
            });
    
            const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
            const savedProgress = JSON.parse(localStorage.getItem(progressKey)) || {};

            savedProgress.skippedQuestions = Array.from(updatedSkipped);
            localStorage.setItem(progressKey, JSON.stringify(savedProgress));
            return updatedSkipped;
        });
    };
    useEffect(() => {
        const loadPayPalScript = () => {
            if (!showPayPal) return;
            const finalTotalCost = "58.49";

            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${PAYPAL_CLIENT_ID}&currency=USD`;
            script.async = true;
            script.onload = () => {
                window.paypal.Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                            purchase_units: [{
                                amount: { value: finalTotalCost },
                            }],
                        });
                    },
                    onApprove: async (data, actions) => {
                        try {
                            const details = await actions.order.capture();
                            alert('Transaction completed by ' + details.payer.name.given_name);
                            setPaypalSuccess(true);
                            await handlePaymentAndGenerate(user.email); 
                        } catch (err) {
                            console.error('Error during transaction approval:', err);
                            setPaypalSuccess(false); 
                        }
                    }
                }).render('#paypal-buttons-container'); 
            };

            document.body.appendChild(script);
        };

        loadPayPalScript();
    }, [showPayPal]); 

    useEffect(() => {
        const checkForSavedProgress = async () => {
            if (savedProgress) {
                const parsedProgress = JSON.parse(savedProgress);
                const { activeModuleIndex } = parsedProgress;
                    if (activeModuleIndex === undefined || activeModuleIndex === 0 ||  activeModuleIndex >= modules.length) {
                    console.log("No progress found due to undefined activeModuleIndex. Fetching from server...");
                    return fetchQuizData();
                }
                restoreProgress(parsedProgress);
                const moduleQuestions = modules[activeModuleIndex] || [];
                setQuestion(moduleQuestions);
            } else {
                await fetchQuizData();
            }
        };
        if (user && user.email) {  
            checkForSavedProgress();
        }
    }, [user]);  
    

    const restoreProgress = (restoredProgress) => {
        const { activeModuleIndex, currentQuestionIndex, answeredQuestions = [], selectedOption= {}, explanationText, pauseCount,  totalPauseDuration } = restoredProgress;

        if (!restoredProgress) return;
        

        const moduleQuestions = modules[activeModuleIndex]?.questions || [];
        const questions = modules[activeModuleIndex]?.questions || [];
         const restoredAnsweredQuestions = Array.isArray(answeredQuestions) 
        ? new Set(answeredQuestions) 
        : new Set(Object.values(answeredQuestions).flat()); 
        const totalCount = restoredAnsweredQuestions.size;
        const { skippedQuestions = [] } = restoredProgress;
        
        const { 
          timeLeft,
          questionId,
          correctAnswer,
          answerDetails,
          progressCount,
          totalCorrectAnswers,
          scaledScore, 
          quizFinished, 
          lastQuizCompleted,
        } = restoredProgress;

        setActiveModuleIndex(activeModuleIndex !== undefined ? activeModuleIndex : 0);
        setCurrentQuestionIndex(currentQuestionIndex || 0);
        setSkippedQuestions(new Set(skippedQuestions));
        setQuestionId(moduleQuestions[currentQuestionIndex]?.id || null);
        setQuestion(moduleQuestions);
        setCorrectAnswer(moduleQuestions[currentQuestionIndex]?.correctAnswer || null);
        setSelectedOption(selectedOption);
        setAnsweredQuestions(restoredAnsweredQuestions);

        const restoredIsCorrect = selectedOption[currentQuestionIndex]?.isCorrect || false;
        const restoredExplanation = selectedOption[currentQuestionIndex]?.explanation || explanationText || "";

        if (restoredAnsweredQuestions.has(currentQuestionIndex)) {
            setShowExplanation(true);  
            setIsCorrect(restoredIsCorrect);  
            setExplanationText(restoredExplanation);  
        } else {
            setShowExplanation(false);
            setIsCorrect(null);
            setExplanationText("");
        }
    
            setTotalAnsweredQuestions(totalCount);
            setPauseCount(pauseCount);  
            setTotalPauseDuration(totalPauseDuration);  
            setTimeLeft(timeLeft !== undefined ? timeLeft : 1920);
        
            setAnswerDetails({
                ...answerDetails
            });
            setProgressCount(progressCount);
            setTotalCorrectAnswers(totalCorrectAnswers !== undefined ? totalCorrectAnswers : 0);
            setLastQuizCompleted(lastQuizCompleted || DEFAULT_QUIZ_ID);
            setIsRestoration(true);
            setQuizFinished(quizFinished || false);
        return { quizFinished, lastQuizCompleted };
    };

    useEffect(() => {
        const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
        const savedProgress = localStorage.getItem(progressKey);

        if (savedProgress) {
            setIsRestoration(true);
        }
    }, [user]); 

    useEffect(() => {
        const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
        const savedProgress = localStorage.getItem(progressKey);

        if (savedProgress) {
            const parsedProgress = JSON.parse(savedProgress);
            const progressState = restoreProgress(parsedProgress);
           
       
        }
    }, [user, modules]);

    const saveProgress = useCallback((officialAnswer) => {
        const newAnsweredCount = totalAnsweredQuestions;
    
        const currentTimeLeft = !isRestoration ? 1920 : (answerDetails?.timeLeft || timeLeft);
        const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
        const existingProgress = JSON.parse(localStorage.getItem(progressKey)) || {};
        const moduleQuestions = modules[activeModuleIndex] || [];
        const currentQuestion = moduleQuestions[currentQuestionIndex] || {};
        const correctAnswer = currentQuestion.correctAnswer || null;
        const questionText = currentQuestion.question || "";

        const mergedselectedOption = {
            ...existingProgress.selectedOption,
            ...(officialAnswer || {})
        };
        const mergedIsCorrect = {
            ...existingProgress.isCorrect,
            [currentQuestionIndex]: isCorrect 
        };

        const mergedAnsweredQuestions = Array.from(answeredQuestions); 
        const mergedSkippedQuestions = Array.from(skippedQuestions).filter((q) => !answeredQuestions.has(q));

        
        const progressData = {
            activeModuleIndex: activeModuleIndex,
            moduleTitle: moduleTitle,
            timeLeft: currentTimeLeft,
            currentQuestionIndex, 
            skippedQuestions: mergedSkippedQuestions,
            question: questionText,
            progressCount,
            answeredQuestions: mergedAnsweredQuestions,
            selectedOption: mergedselectedOption, 
            correctAnswer,
            quizFinished: quizFinished || false,
            lastQuizCompleted: quizID, 
            answerDetails: {
                ...answerDetails,
                timeLeft: currentTimeLeft
            },
            totalAnsweredQuestions: newAnsweredCount,
            totalCorrectAnswers: totalCorrectAnswers || 0,
            pauseCount: pauseCount, 
            totalPauseDuration: totalPauseDuration
        };

        try {
            localStorage.setItem(progressKey, JSON.stringify(progressData));

        } catch (error) {
            console.error("Error saving progress:", error);
        }
    }, [activeModuleIndex, moduleTitle, timeLeft, currentQuestionIndex, questionId, question, selectedOption, answeredQuestions, quizFinished, answerDetails, totalAnsweredQuestions, totalCorrectAnswers, pauseCount, totalPauseDuration, skippedQuestions, correctAnswer, isCorrect, progressCount ] 
        );

  
        const formatDuration = (durationInSeconds) => {
            const hours = Math.floor(durationInSeconds / 3600);
            const minutes = Math.floor((durationInSeconds % 3600) / 60);
            const seconds = durationInSeconds % 60;
        
            let formattedTime = '';
            if (hours > 0) {
                formattedTime += `${hours}h `;
            }
            if (minutes > 0 || hours > 0) {
                formattedTime += `${minutes}m `;
            }
            formattedTime += `${seconds}s`;
        
            return formattedTime.trim();
        };
        

    const handleModuleComplete = () => {
        if (activeModuleIndex === 0){
            localStorage.clear(); 
            
            const extraTimeInMinutes = totalPauseDuration / 60;
            setExtraTimeAfforded(extraTimeInMinutes);

            setProgressCount(totalAnsweredQuestions);
            setTimeLeft(1920);
            
            const nextModuleIndex = activeModuleIndex + 1;
            const moduleQuestions = modules[nextModuleIndex] || [];
            setActiveModuleIndex(nextModuleIndex);
            setQuestion(moduleQuestions);
            setIsRestoration(true);
            const nextModuleTitle = `Module ${nextModuleIndex + 1}`.toUpperCase(); 
        } else if (activeModuleIndex === 1) {
            // Second module (final module) completed, finalize quiz
            setQuizFinished(true);
            handleQuizFinished();
        }
    };
    
     
    const fetchQuizData = async () => {
        setActiveModuleIndex(0);
        setIsLoading(true);
        try {
            const progressResponse = await axiosInstance.get(`${API_URL}/api/sat-quiz/progress`, { params: { email: user.email } });
            const progressData = progressResponse.data;

            if (progressData && progressData.lastQuizCompleted) {
                const nextQuizID = getNextQuizID(progressData.lastQuizCompleted);
                const nextQuizResponse = await axiosInstance.get(`${API_URL}/api/sat-quiz`, { params: { quizID: nextQuizID } });

            if (nextQuizResponse.data.noMoreQuizzes) {
                setNoMoreQuizzes(true);
                setQuizFinished(true)
                alert(nextQuizResponse.data.message);
                setIsLoading(false);
                setIsAnimationPlaying(true);

                const  { hours, startDate, highestScore } = await getIndependentStudyHours(user.email);
                setCertificateData({
                    name: progressData.userName,
                    startDate,
                    completionDate: progressData.lastTestDate,
                    score: highestScore,
                    hours
                });

                
                setTimeout(async () => {
                    await generateCertificate({
                        ...certificateData,
                        updateStatusMessage 
                    });
                setIsAnimationPlaying(false); 
            }, 3000);
        };

        const parsedQuestions = parseQuestions(nextQuizResponse.data);
        setModules(parsedQuestions);
   
        setActiveModuleIndex(0);
        setQuizID(nextQuizID);
        
        const skippedSet = new Set();
        
        parsedQuestions.forEach((module, moduleIndex) => {
            module.forEach((question, questionIndex) => {
                const questionId = `${moduleIndex}-${questionIndex}`;
                if (!answeredQuestions.has(questionId)) {
                    skippedSet.add(questionId); 
                }
            });
        });
        setSkippedQuestions(skippedSet);
        setIsLoading(false);
            
            } else {
               
                const defaultQuizResponse = await axiosInstance.get(`${API_URL}/api/sat-quiz`, { params: { quizID: DEFAULT_QUIZ_ID } });

                const parsedQuestions = parseQuestions(defaultQuizResponse.data);
                const firstModuleQuestions = parsedQuestions[0] || [];
                const skippedSet = new Set();
                parsedQuestions.forEach((module, moduleIndex) => {
                    module.forEach((question, questionIndex) => {
                        skippedSet.add(`${moduleIndex}-${questionIndex}`);
                    });
                });
                setActiveModuleIndex(0);
                setModules(parsedQuestions);
                setQuestion(firstModuleQuestions);
                setQuizID(DEFAULT_QUIZ_ID);
                setIsLoading(false);
                
            }
        } catch (error) {
            console.error("Error fetching quiz or progress data:", error);
            setIsLoading(false);
        }
    };

    const getNextQuizID = (quizID) => {
        const quizIDs = ['QUIZ_1', 'QUIZ_2', 'QUIZ_3']; 
        const currentIndex = quizIDs.indexOf(quizID);
        return quizIDs[currentIndex + 1] || quizIDs[0]; 
    };



    const parseQuestions = (data) => {
        const modules = [];
        Object.keys(data).forEach(moduleKey => {  
            const moduleIndex = parseInt(moduleKey.replace('Module ', '')) - 1;
            modules[moduleIndex] = data[moduleKey].map(item => ({
                passage: item.passage || null,
                question: item.question,
                options: item.options.map(opt => ({
                    label: opt.label,
                    option: opt.option,
                    explanation: opt.explanation
                })),
                correctAnswer: item.correctAnswer,
                image_url: item.image_url === "null" ? null : item.image_url,
                table: item.table === "null" ? null : item.table
            }));
        });
    
        return modules;
    };
    
    useEffect(() => {
        const answeredCount = answeredQuestions.size; 
        setTotalAnsweredQuestions(answeredCount);
        saveProgress(answeredCount);
    }, [answeredQuestions, saveProgress]);

    const handleAnsweredQuestions = ({
        timeLeft, 
        incrementTotalAnswered        
    }) => {
        setAnsweredQuestions(prev => {
            const updatedAnswered = new Set(prev);
            updatedAnswered.add(currentQuestionIndex); 
            return updatedAnswered; 
        });
        setHasAnswered(prev => {
            const updatedHasAnswered = new Set(prev);
            updatedHasAnswered.add(currentQuestionIndex); 
            return updatedHasAnswered; 
        });
        setAnswerDetails({
            timeLeft,       
        });
         if (incrementTotalAnswered) {
        setTotalAnsweredQuestions(prev => {
            const updatedTotal = prev + 1;
            
          
            const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
            const savedProgress = JSON.parse(localStorage.getItem(progressKey)) || {};
            savedProgress.totalAnsweredQuestions = updatedTotal;
            localStorage.setItem(progressKey, JSON.stringify(savedProgress));
            
            return updatedTotal;
        });
    }
       
        setSkippedQuestions(prevSkipped => {
            const updatedSkipped = new Set(prevSkipped);
            const questionKey = `${activeModuleIndex}-${currentQuestionIndex}`;
            updatedSkipped.delete(questionKey); 
            const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
            const savedProgress = JSON.parse(localStorage.getItem(progressKey)) || {};

            savedProgress.skippedQuestions = Array.from(updatedSkipped);
            localStorage.setItem(progressKey, JSON.stringify(savedProgress));
            return updatedSkipped; // Return the updated skipped questions
        });
    };
    
    useEffect(() => {
        const answeredCount = answeredQuestions.size; 
        setTotalAnsweredQuestions(answeredCount); 
    }, [answeredQuestions]);

    useEffect(() => {
        saveProgress(totalAnsweredQuestions);
    }, [totalAnsweredQuestions]);

        const onCorrectAnswer = () => {
            setTotalCorrectAnswers(prev => prev + 1); 
            saveProgress();
        };
        
    const handlePauseUpdate = (newPauseCount, newTotalPauseDuration) => {
        // Only update pause count if a valid value is passed
        if (newPauseCount !== null && newPauseCount !== undefined) {
            setPauseCount(newPauseCount); 
            saveProgress(newPauseCount); 
        }
        
       
        if (newTotalPauseDuration !== null && newTotalPauseDuration !== undefined) {
            setTotalPauseDuration(newTotalPauseDuration); 
            saveProgress(null, newTotalPauseDuration); 
        }
    };
    
    useEffect(() => {
        if (quizFinished) {
            setQuizFinished(true);
        }
    }, [quizFinished]);
    
    const handleQuizFinished = async () => {
        try {
            
            const finalScaledScore = calculateScaledScore(totalCorrectAnswers);
            setScaledScore(finalScaledScore);
          
            setCertificateData(prev => ({ ...prev, hours: totalHours }));

          
            await axiosInstance.post(`${API_URL}/api/sat-quiz/progress`, {
                email: user?.email || 'anonymous',
                testScore: finalScaledScore,
                lastQuizCompleted: quizID,
                totalHours: (totalHours),
            });
    
            const progressKey = user?.email ? `${user.email}_quizProgress` : 'quizProgress';
            setTotalPauseDuration(0);
            setPauseCount(0);
            setProgressCount(0);
            localStorage.removeItem(progressKey);
    
            setQuizFinished(false);
            
            setTimeout(() => {
                fetchQuizData();
            }, 5000);
    
        } catch (error) {
            console.error('Error saving progress or fetching next quiz:', error);
        }
    };
    
    const getIndependentStudyHours = async (email) => { 
        try {
            const response = await axiosInstance.get(`${API_URL}/api/sat-quiz/hours`, { params: { email } });
            const hours = response.data.independentStudyHours;
            const startDate = response.data.startDate;
            const highestScore = response.data.highestScore;
           
            setIndependentStudyHours(hours);
            return { hours, startDate, highestScore };
        } catch (error) {
            console.error("Error fetching independent study hours:", error);
            return 0;
        }
    }
    const handleGenerateClick = () => {
        setShowPayPal(true);
    };

    const requestTranscript =  async(email) => {
        try {
            const response = await axiosInstance.get(`${API_URL}/api/sat-quiz/transcript`, { params: { email: user.email } });

            const transcriptUrl = response.data.url; 
       
        setTranscriptUrl(transcriptUrl);
        return transcriptUrl; 
    } catch (error) {
        console.error('Error fetching transcript URL:', error);
        throw error; // Optionally re-throw the error if needed
    }

    };

    const handlePaymentAndGenerate = async (email) => {
        try {
          const transcriptUrl = await requestTranscript(user.email); 
          if (transcriptUrl && paypalSuccess) { 
            const qrCodeImage = await generateQRCode(transcriptUrl);
            
            await generateCertificate({
              ...certificateData,
              qrCodeImage,
              updateStatusMessage,
            });
          }
        } catch (error) {
          console.error('Error generating certificate or QR code:', error);
        }
      };
    
    
    return (
        <div className="quiz-container">
            {isLoading ? (
                <AtomSpinner color="#ffffff" size={60} />
            ) : (
                <>
                    <div className="confetti-container"></div>
                    {!noMoreQuizzes && (
                        <ProgressBar 
                            answeredCount={progressCount + totalAnsweredQuestions}  
                            total={modules.reduce((acc, module) => acc + module.length, 0)}  
                            quizFinished={quizFinished}
                        />
                    )}
                       {noMoreQuizzes ? (
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    {showPayPal ? (
                        <div className="paypal-container">
                            <div id="paypal-buttons-container"></div>
                        </div>
                    ) : (
                        <>
                            {/* Separate container for QR and messages */}
                            <div className="qr-and-message-container">
                                {/* QR Code Container */}
                                <div className="generate-qr-container">
                                    <div className="generate-qr-text">
                                        <div className="qr-title">
                                            <RiQrCodeLine style={{ marginRight: '8px', fontSize: '24px' }} />
                                            <h2>Generate QR Code</h2>
                                        </div>
                                        <p>Share your official transcript digitally.</p>
                                    </div>
                                    <button
                                        className="generate-button"
                                        onClick={handlePaymentAndGenerate}
                                    >
                                        Generate
                                    </button>
                                </div>

                                {/* Monkey Animation */}
                                <div className="monkey-animation">
                                    <Lottie
                                        animationData={monkeyAnimation}
                                        height={150} 
                                        width={150} 
                                    />
                                </div>

                                {/* Monkey Message */}
                                <div className="monkey-message">
                                    <h2>{statusMessage}</h2>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            ) : !quizFinished && modules.length > 0 && !noMoreQuizzes  ? (
                        <Module
                            key={activeModuleIndex}
                            moduleTitle={moduleTitle}
                            currentQuestionIndex={currentQuestionIndex}
                            setCurrentQuestionIndex={setCurrentQuestionIndex}
                            moduleTime={timeLeft}
                            onTimeLeftChange={setTimeLeft} 
                            questions={modules[activeModuleIndex]}
                            onAnswerSelected={handleAnsweredQuestions}
                            onCorrectAnswer={onCorrectAnswer} 
                            onComplete={handleModuleComplete}
                            onPauseUpdate={handlePauseUpdate}
                            pauseCount={pauseCount}
                            totalPauseDuration={totalPauseDuration}
                            isRestoration={isRestoration} 
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            onSetSelectedOption={onSetSelectedOption}
                            answeredQuestions={answeredQuestions} 
                            setAnsweredQuestions={setAnsweredQuestions} 
                            hasAnswered={hasAnswered} 
                            setHasAnswered={setHasAnswered}  
                            showExplanation={showExplanation} 
                            setShowExplanation={setShowExplanation} 
                            explanationText={explanationText} 
                            setExplanationText={setExplanationText} 
                            question={question}                    
                            setQuestion={setQuestion}              
                            isCorrect={isCorrect}                  
                            setIsCorrect={setIsCorrect}            
                            correctAnswer={correctAnswer}          
                            setCorrectAnswer={setCorrectAnswer}  
                            correctAnswersInModule={correctAnswersInModule}  
                            setCorrectAnswersInModule={setCorrectAnswersInModule} 
                            skippedQuestions={skippedQuestions} 
                            setSkippedQuestions={setSkippedQuestions}
                            onSetSkippedQuestions={onSetSkippedQuestions}
                            questionId={questionId}
                            setQuestionId={setQuestionId}
                        />
                    ) : quizFinished && !noMoreQuizzes ? (
                        <>
                            <div className="message">
                                <h2>Study Session Complete!</h2>
                                <p>English & Writing Score:</p>
                                <div className="score">
                                    <h2>{calculateScaledScore(totalCorrectAnswers)}</h2>
                                    {pauseCount > 0 && (
                                        <p style={{ color: 'grey' }}>
                                            Timer: {pauseCount} total hits affording {extraTimeAfforded ? extraTimeAfforded.toFixed(2) : (totalPauseDuration / 60).toFixed(2)} extra minutes.
                                        </p>
                                    )}
                                </div>
                            </div>
                            {quizFinished && (
                                <div className="happy-animation" style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', zIndex: 0 }}>
                                    <Lottie
                                        animationData={animationData}
                                        loop={true} 
                                        style={{ width: '100%', height: '100%' }}
                                    />
                                </div>
                            )}
                        </>
                    ) : (
                        <p></p>
                    )}
                </>
            )}
        </div>
    );
}

export default Quiz;