import React, { useState, useEffect } from 'react';
import Quiz from '../Components/SAT/Quiz'; // Import the Quiz component
import './SATDashboard.css'; // Import the CSS file for dashboard styling

const SATDashboard = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Simulate any potential loading logic (e.g., fetching user/session data)
        setTimeout(() => {
            setIsLoading(false);
        }, 1000); // Simulate loading delay
    }, []);

    return (
        <div className="sat-dashboard-container">
            {isLoading ? (
                <div className="loading">
                    <h2>Loading SAT Dashboard...</h2>
                </div>
            ) : (
                <div className="quiz-wrapper">
                    <Quiz />
                </div>
            )}
        </div>
    );
};

export default SATDashboard;
