import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; // Ensure this path is correct
import './Hook.css'; // Import CSS for PrivacyComponent if needed

const HookB = () => {
  const [action, setAction] = useState("Document Preparation"); // Initial state is "Document Preparation"
  const navigate = useNavigate(); // Use navigate for navigation

  const handleHomeClick = () => {
    navigate('/home'); // Navigate to home page
  };

  const handleDocumentPreparationClick = () => {
    setAction("Document Preparation"); // Set action state to "Document Preparation" when clicked
  };

  const handleConfidenceClick = () => {
    setAction("Confidence"); // Set action state to "Confidence" when clicked
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "Document Preparation" ? "Document Preparation" : "Confidence"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
        <div className="header">
          <div className="text">{action === "Document Preparation" ? "Premium Services" : "Premium Services"}</div>
          <div className="underline"></div>
        </div>

        {action === "Document Preparation" ? (
          <>
          <br /><br /><br />
            <h4>Are You Ready to Unleash Your Potential?</h4><br />
            <p>Many believe that their success is limited without the right connections, but that's not always true. Without the proper outlet to exercise your talent and heartfelt engagement, the process of achieving your vision can become monotonous and unfulfilling, leading to burnout or limited success.
              <br /><br />
              When you're ready, we're here to help you transform trepidation into triumph and leverage your talent, so you can secure a more positive outcome. </p>
          </>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <br /><br /><br />
            <h4>Envision a Whole New Reality.</h4><br />
            <p>First, we'll research the technical, legal, and/or academic requirements. Then, we'll prepare your documentation. Finally, we'll include industry-specific keywords to ensure your bid, proposal, government application, or resume is well-suited.
              <br /><br />We take care of the creative design, wordcraft, research, and revision, so all you'll need to do is submit your documentation and prepare to be selected.</p>
          </div>
        )}
      </div>

            <div className="submit-container">
        {action === "Document Preparation" ? (
          <>
            <div className="submit gray" onClick={handleHomeClick}>Go Back</div> {/* Gray here */}
            <div className="submit" onClick={handleConfidenceClick}>Learn More</div> {/* Normal here */}
          </>
        ) : (
          <>
            <div className="submit gray" onClick={handleDocumentPreparationClick}>Go Back</div> {/* Gray here */}
            <Link to="/price-calculator" className="submit">Get Started</Link> {/* Normal here */}
          </>
        )}
      </div>

    </Mount>
  );
};

export default HookB;
