import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Mount from '../Mount'; // Ensure this path is correct
import './CGI.css'; // Import CSS for SAT component

const CGI = () => {
  const [action, setAction] = useState("CGI"); // Initial state is "CGI"
  const navigate = useNavigate(); // Use navigate for navigation

  const handleHomeClick = () => {
    navigate('/home'); // Navigate to home page
  };

  const handleCGIClick = () => {
    setAction("CGI"); // Set action state to "CGI" when clicked
  };

  const handleLearnMoreClick = () => {
    setAction("LearnMore"); // Set action state to "LearnMore" when clicked
  };

  const handleExploreClick = () => {
    navigate('/error'); // Navigate to the articles page
  };

  return (
    <Mount>
      <Helmet>
        <title>{action === "CGI" ? "CGI" : "Explore"} - Contranorm Media & Production</title>
      </Helmet>
      <div className="page">
      <div className="header">
          <div className="text">{action === "CGI" ? "3D Characters, Models, & Animations" : "Digital Technologies"}</div>
          <div className="underline"></div>
        </div>
        
        {action === "CGI" ? (
          <>
          <br /><br /><br />
            <h4>Did you know that our independent creators can design and create customized 3D content for you?</h4>
            <br />
            <br />
            <p>Try Contranorm Media & Production's Custom Content Creation Service.</p>
          </>
        ) : (
          
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}> 
          <h4 style={{ margin: '20px', textAlign: 'center', width: '80%', borderRadius: '8px' }}>
            <br /><br /><br />
          Let our skilled 3D artists bring your vision to life.
          </h4>
            <p style={{ textAlign: 'justify' }}>
              Finding the right way to express your ideas, present a cool concept, or convey information can be time consuming and require a specialized skill set that you may not have mastered just yet, but we've done all the heavy lifting for you, so all you'll have to do is focus on sharing your content. 
            </p>
            <br />
    
          
            <p style={{ textAlign: 'left' }}>
              Check out what we &nbsp;
              <Link to="/error" className="link-with-space">create.</Link>
             
            </p>
          </div>
        )}
      </div>
        
      
              <div className="submit-container">
          {action === "CGI" ? (
            <>
              <div className="submit gray" onClick={handleHomeClick}>Go Back</div> {/* Gray here */}
              <div className="submit" onClick={handleLearnMoreClick}>Learn More</div> {/* Normal here */}
            </>
          ) : (
            <>
              <div className="submit gray" onClick={handleCGIClick}>Go Back</div> {/* Gray here */}
              <div className="submit" onClick={handleExploreClick}>Explore</div> {/* Normal here */}
            </>
          )}
        </div>

    </Mount>
  );
};

export default CGI;
