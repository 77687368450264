import QRCode from 'qrcode';  

export async function generateQRCode(transcriptUrl) {
    try {
        // Generates the QR code as a base64 string
        const qrCodeBase64 = await QRCode.toDataURL(transcriptUrl);
        return qrCodeBase64; 
    } catch (error) {
        console.error("Error generating QR Code:", error);
    }
}

